import React from 'react';
import './about.css'
import Spacing from '../spacing';
import graphic from '../assets/about/graphic.png'

function AboutBanner() {
    return (
        <>
            <div className='about_banner_main'>
            <Spacing lg="10" md="80" />
            <div className='about_banner_sub'>

            <Spacing lg="50" md="10" />
            <div className='about_banner_sub_left'>
                <div className='about_banner_main_text'>
                    <button>Our Vision</button>
                    <Spacing lg="30" md="80" />
                    <p>Our vision is a future where critical medical assistance is easily accessible
                         and delivered with unmatched efficiency, ensuring better outcomes for all in need</p>
                </div>
               
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-2'></div>
                      
                        <div className='col-lg-4 '>
                        <Spacing lg="50" md="80" />
                        <div className='bg_card_about'>
                            <h4>Our mission is to simplify access to critical care and enhance emergency medical services through advanced cutting-edge technologies</h4>
                            <span> <p>-</p>Our Mission</span>
                        </div>
                        </div>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-4'>
                            <img src={graphic} alt="" />
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>
                </div>
                </div>
            </div>

        </>
    )
}

export default AboutBanner
