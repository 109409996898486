import React from 'react'
import Client from '../home/client'
import Hero from '../impact/Hero'
import HomePage from '../home/homePage';

function Layout() {
  return (
    <>
    <HomePage />
    {/* <Hero /> */}
    
    </>
  )
}

export default Layout
