import React from "react";
import "./footer.css";
import Spacing from "../spacing";
// import Metaloklogo from "../icons/metaloklogo.png";
import logo from "../assets/impact/logo.png";
import send from '../assets/icons/send.png'
//import sportslogo from "../icons/sportslogo.png";
// import footerfacebook from "../icons/footer-facebook.png";
// import footerinsta from "../icons/footer-insta.png";
// import footertwitter from "../icons/footer-twitter.png";
// import footerlinkdin from "../icons/footer-linkdin.png";
// import footerdiscard from "../icons/footerdiscard.png";
// import footertelegram from "../icons/footertegram.png";
// import { NavLink } from "react-router-dom";

export default function Footer() {
  function Toptoscroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <footer className="footer-bg">
        
     
        <div className="container ">
          <div className="row footer-r  kode_footer_bg">
          <Spacing lg="50" md="10" />
        
            <div className="col-lg-4 col-12 sports-logo-column">
              <div className=" div-logo-footer">
                <img
                  className=""
                 
                  src={logo}
                  alt=""
                />
             
              </div>
              <h4 className="copy-right-text py-3">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat ut wisi enim ad minim
              </h4>
              <div className="footer-meta-social ">
              
                <div className="footer-social-header">
                  <div className="footer-social-icons">
                    <a
                      href=" "
                      target="_blank"
                    >
                      {/* <img
                        src={footerfacebook}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                  <div className="footer-social-icons">
                    <a
                      href=""
                      target="_blank"
                    >
                      {/* <img
                        src={footerinsta}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                  <div className="footer-social-icons">
                    <a href="" target="_blank">
                      {/* <img
                        src={footertwitter}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                  <div className="footer-social-icons">
                    <a
                      target="_blank"
                      href=""
                    >
                      {/* <img
                        src={footerlinkdin}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                </div>
                <div className="footer-social-header-1">
                  <div className="footer-social-icons">
                    <a
                      target="_blank"
                      href=""
                    >
                      {/* <img
                        src={footerdiscard}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                  <div className="footer-social-icons">
                    <a target="_blank" href="">
                      {/* <img
                        src={footertelegram}
                        alt=""
                        className="footer-social-icons-img"
                      /> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-2 col-4 mt-4 mt-lg-0 pl-3">
              <ul className="footer-links">
                <a href="/ ">
                  {" "}
                  <li>Home</li>
                </a>
              </ul>
              <ul className="footer-links py-4">
                <a href="#">
                  {" "}
                  <li>How it Works?</li>
                </a>
              </ul>
              <ul className="footer-links pb-4">
                <a href="#">
                  <li>Product</li>
                </a>
              </ul>

              <ul className="footer-links  pb-4">
                <a href="#">
                  <li>Testimonials</li>
                </a>
              </ul>
              {/* <ul className="footer-links ">
              <a href="/careerPage"><li>Career</li></a>
              </ul> */}
            </div>

            <div className="col-lg-2 pl-lg-5 pl-2 col-4 mt-4 mt-lg-0 ">
              <ul className="footer-links pl-lg-5 pl-4">
                <a href="/impact">
                  <li>Impact</li>
                </a>
              </ul>

              <ul className="footer-links pl-lg-5 pl-4 py-4">
                <a href="/About">
                  <li>About us</li>
                </a>
              </ul>

              <ul className="footer-links pl-lg-5 pl-4 pb-4">
                <a href="#">
                  <li>Contact us</li>
                </a>
              </ul>

              <ul className="footer-links pl-lg-5 pl-4 pb-4">
                {/* <a href="/SupportPage">
                  <li>Support</li>
                </a> */}
              </ul>
              {/* <ul className="footer-links pl-lg-5 pl-4">
                <a href="https://metalok.io"><li>Metalok</li></a>
              </ul> */}
            </div>

            <div className="col-lg-2 col-4 mt-4 mt-lg-0 pl-md-5 pl-3">
              <ul className="footer-links">
                <li>Request a demo</li>
              </ul>

              <ul className="footer-links py-4">
                {/* <a href="https://qr.page/g/lU6tKBy1JD" target="_blank">
                  <li>Privacy Policy</li>
                </a> */}
              </ul>

              <ul className="footer-links pb-4">
                {/* <li>
                 
                    Contact us
                 
                </li> */}
              </ul>
              <ul className="footer-links">
                {/* <li>Legal Disclaimer</li> */}
              </ul>
            </div>
            <div className="col-lg-2 col-4 mt-4 mt-lg-0 pl-md-5 pl-3">
              <ul className="footer-links">
                <li>Newsletter</li>
              </ul>

              <ul className="footer-links py-4">
               <li>
               <form className="form-footer">
        <input type="email" name=""   className=" placeholder-text" placeholder="Email..." />
        
        
       
        <button type="submit" className="footer-button"> <img src={send} alt=""  className="send-icon"/>
      </button>
       
      </form>
               </li>
              </ul>

              <ul className="footer-links pb-4">
              
              </ul>
              <ul className="footer-links">
               
              </ul>
            </div>
            <Spacing lg="50" md="10" />
          </div>
        </div>
      </footer>
    </>
  );
}