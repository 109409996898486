// import React from 'react'
// import bgImg from '../assets/impact/3.png';
// import accenture from '../assets/icons/1.png'
// import air from '../assets/icons/2.png'
// import sky from '../assets/icons/4.png'
// import react from '../assets/icons/3.png'
// import { useForm } from 'react-hook-form';
// import './login.css'

// export default function Form() {

//     const { register, handleSubmit, watch, formState: { errors } } = useForm()
//     const onSubmit = data => console.log(data);

   

//     return (
//         <section>
//             <div className="register">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-6 col-lg-6">
//                             <div className="container">
//                                 <div className="row">
//                                     <div className="col-md-2 col-lg-2">
//                                     </div>
//                                     <div className="col-md-10 col-lg-10">
                                       
//                                         <div className='login_right_text'>
//                                             <h1>See why the world’s best doctors use Kode blue to bring a change.</h1>
//                                             <div className='boreder_down'>
//                                                 <p></p><span>Trusted by</span><p></p>
//                                             </div>

//                                         </div>

//                                         <div className='container'>
//                                             <div className='row login_images_div'>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'> <img src={accenture} alt="" /></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6' ></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'><img src={react} alt="" /></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'></div>
//                                             </div>
//                                             <div className='row login_images_div'> 
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'> <img src={air} alt="" /></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'><img src={sky} alt="" /></div>
//                                                 <div className='col-md-2 col-lg-2 col-sm-6'></div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>
//                         <div className="col-md-6 col-lg-6">
//                             <div className="container">
//                                 <div className="row">
//                                     <div className="col-md-9 col-lg-9 col-sm-12">
//                                         <div className='form-bg'>
//                                             <div className='form-white'>
//                                                 <span className='sign-up-text'>Sign Up</span>
                                          

//                                                 <form id='form' className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
//                                                     <input type="text" {...register("username")} placeholder='Your primary email' />
//                                                     <input type="text" {...register("password")} placeholder='Phone Number' />
//                                                     <input type="text" {...register("confirmpwd")} placeholder='Organization Name' />
                                                  
//                                                     {errors.mobile?.type === "required" && "Mobile Number is required"}
//                                                     {errors.mobile?.type === "maxLength" && "Max Length Exceed"}
//                                                     <button className='btn'>Get a call back</button>
//                                                 </form>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }
import React from 'react';
import { useForm } from 'react-hook-form';
import './login.css';

import bgImg from '../assets/impact/3.png';
import accenture from '../assets/icons/1.png';
import air from '../assets/icons/2.png';
import sky from '../assets/icons/4.png';
import react from '../assets/icons/3.png';

export default function Form() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  return ( 
  <div className=" ">

    <section className=''>
      
      <div className="register ">
      <div className=" ">
      <div className=" plus_image_login">
        <div className="container   ">
       
          <div className="row  ">
            <div className="col-md-12 col-lg-5 col-xl-6 col-12 col-sm-12 ">
              <div className="container ">
                <div className="row ">
                  <div className="col-md-2 col-lg-2"></div>
                  <div className="col-md-10 col-lg-10">
                    <div className='login_right_text'>
                      <h1>Together Towards  Better</h1><h1> Health</h1>
                      <div className='boreder_down'>
                        <p></p><span>Trusted by</span><p></p>
                      </div>
                    </div>
                    <div className='container'>
                      <div className='row login_images_div'>
                      
                        <div className='col-md-4 col-lg-6  col-xl-6 col-sm-6 col-6'> <img src={accenture} alt="" /></div>
                        {/* <div className='col-md-4 col-lg-5 col-xl-5 col-sm-6 col-6'> <img src={react} alt="" /></div> */}
                        <div className='col-md-4 col-lg-6  col-xl-6 col-sm-6 col-6'> <img src={react} alt="" /></div>
                       
                      </div>
                     
                       <div className='row login_images_div'>
                       
                        <div className='col-md-4 col-lg-6 col-xl-6 col-sm-6 col-6'> <img src={air} alt="" /></div>
                        <div className='col-md-4 col-lg-6  col-xl-6 col-sm-6 col-6'> <img src={sky} alt="" /></div>
                       
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 col-xl-6 col-12 col-sm-12 ">
              <div className="container ">
                <div className=''>
                <div className="row ">
                  <div className="col-md-9 col-lg-11 col-sm-12 m-auto">
                    <div className='form-bg'>
                      <div className='form-white'>
                        <div className='sign-up-text'>Sign Up</div>
                        <form id='form' className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
                          <input type="text" {...register("username")} placeholder='Your primary email' />
                          <input type="text" {...register("password")} placeholder='Phone Number' />
                          <input type="text" {...register("confirmpwd")} placeholder='Organization Name' />
                          {errors.mobile?.type === "required" && <p>Mobile Number is required</p>}
                          {errors.mobile?.type === "maxLength" && <p>Max Length Exceeded</p>}
                          <button className='btn'>Get a call back</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </section>
    </div>
  );
}
