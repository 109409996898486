import React from 'react'; 
import './patient.css';
import patent from '../assets/impact/6.png'
import hospital from '../assets/impact/7.png'
import Spacing from '../spacing';

export const PatientOne = () => {
  return (
    <div>
       <div className=''>
            <div className='our_impact_main_head'>
                <h2>Our Impact</h2>
            </div>
        </div>
        <Spacing lg="50" md="80" />
        <div className='container'>
          <div className='row'>
            <div className='col-md-7'>
              <div className='cardo'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='pa-card'>
                    <h3>For Patients</h3>
                    <p> We help people when they need it the most. something which makes a real difference </p>
                  </div> 
                </div>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>50%</h3>
                    <p>Enhanced Response time</p>
                  </div> 
                </div>
              </div>
              <br/>
              <br/>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>80%</h3>
                    <p>Better Compliance Scores</p>
                  </div> 
                </div>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>30%</h3>
                    <p>Death Rate Can Be Prevented</p>
                  </div> 
                </div>
              </div>

              </div> 
            </div> 
            <div className='col-md-5'>
                <div class='patient_img'>
                    <img src={patent} alt="Patient Image" />
                </div> 
            </div>
          </div>
        </div>

        {/* for hospital */}
        <Spacing lg="50" md="80" />
        <div className='container'>
          <div className='row'> 
            <div className='col-md-5'>
                <div class='patient_img'>
                    <img src={hospital} alt="Patient Image" />
                </div> 
            </div>
            <div className='col-md-7'>
              <div className='cardo-1'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='pa-card'> 
                    <h3>For Hospitals</h3>
                    <p>
Our system holds the potential to foster hospital growth, enhance profitability, and elevate productivity by optimizing staff efficiency</p>
                  </div> 
                </div>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>3x</h3>
                    <p>Speed up your growth</p>
                  </div> 
                </div>
              </div>
              <br/>
              <br/>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>25x</h3>
                    <p>Enhanced ROI (Good Return on investment )</p>
                  </div> 
                </div>
                <div className='col-md-6 col-6'>
                  <div className='pat-card'>
                    <h3>2x</h3>
                    <p>Inc. Productivity-Staff working efficiency</p>
                  </div> 
                </div>
              </div>

              </div> 
            </div> 
          </div>
        </div>

    </div>
  )
}
