import React from 'react'; 
import videoImg from '../assets/images/video.png'

function Watch() {
  return (
    <> 
    <div className='row watch_our_vedio_here_layout'>
      <div className='col-md-6 col-sm-6'>
      <div className='watch_our_vedio_leftside_view'>
            <div className='watch_our_vedio_leftside_emptydiv'></div>
            <img src={videoImg} alt="videoImg" className='watch_our_vedio_img'/>
        </div>
      </div>
      <div className='col-md-6 col-sm-6'>
        <div className='watch_our_vedio_rightside_view'>
            <h2>Watch How We're Redefining Possibilities </h2>
            <div className='watch_our_vedio_rightside_view_line'></div>
            <p >KODE BLUE - Smart Assistant in Emergencies Unveiled. The Journey Through Our Emergency Solution.</p>
        <p>Immediate Care in Your Hands.</p>
        </div> 
      </div>
    </div>

    </>
  )
}

export default Watch;
