import React from 'react'
import factor from '../assets/impact/4.png'

function Factors() {
  return (
    <>
       
    <div className='container  factors-bg'>
    
        <div className='row factors_row'>
            <div className='col-md-12  col-lg-6 col-sm-12 col-12 col-xl-6 factor_colum'>
                <div className='factor-text'>
                    <h1>Enhanced Emergency Preparedness</h1> 
                    {/* <h1>critical care!</h1> */}
                    <p>Streamlined communication and data exchange empower healthcare teams to make informed decisions during emergencies</p>
                </div>
            </div>
            <div className='col-md-12  col-lg-6 col-sm-12 col-12 col-xl-6 factor_colum'>
                <div className=''>
                    <img src={factor} alt="" />
                </div>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default Factors;
