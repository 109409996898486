import React from 'react';
import Logo from "../assets/images/Logo.png";
import RightArrow from "../assets/images/arrow-right.png";
import { Link, NavLink } from 'react-router-dom';

function Navbar() {
    return (
        <div className='navbar_view'>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className='navbar_logo'>
                        <div className="navbar-brand">
                          <NavLink to="/"><img src={Logo} alt='Logo' /></NavLink>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav navbar_items_view me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" activeClassName="active" exact to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" activeClassName="active" to="/impact">Impact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" activeClassName="active" to="/about">About Us</NavLink>
                            </li>
                        </ul>

                        <div className='navbar_item_flex_view'>
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item Login_item">
                                    <a className="nav-link" href="#">Login</a>
                                </li>
                            </ul>
                            <Link to="/request">
                                <button className='request_demo'>Request Demo <img src={RightArrow} alt="Arrow" /></button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;
