import React, { useState, useEffect } from 'react';
import one from '../assets/images/01.png';
// import rectangle from '../assets/images/rectangle.png';
import mobile from '../assets/images/system-mobile.svg';
import laptop from '../assets/images/system-laptop.svg';
import iconOne from '../assets/images/icon/s-one.svg';
import iconTwo from '../assets/images/icon/s-two.svg';
import iconThree from '../assets/images/icon/s-three.svg';
import iconFour from '../assets/images/icon/s-four.svg';
import iconFive from '../assets/images/icon/s-five.svg';
import iconSix from '../assets/images/icon/s-six.svg';
import iconSeven from '../assets/images/icon/s-seven.svg';
import iconEight from '../assets/images/icon/s-eight.svg';


function SystemOne() {
    const texts = ["Cloud Based", "Standard & Secure", "Regulatory Compliant"]; // Add more texts as needed
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 3000);
  
      return () => clearInterval(interval);
    }, []);
  return (
    <>
     <div>
        <div className='container-fluid'>
            <div className='row'>
                <div className='system'>
                    <div className='system-img'> 

                        <div className='row mob-div'>
                            <div className='col-md-6'>
                            <img src={mobile} className='mobile-system'/>
                            </div>
                            <div className='col-md-6'>
                                <div className='system-enable'>
                                    <h2>The system is <span>{texts[currentTextIndex]}</span></h2>
                                    <p>An online end-to-end platform connecting ambulances, hospitals, and stakeholders for prompt critical care</p>
                                
                                    <div className='mob-div-1'>
                                    <div className='row'>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                            <img src={iconOne}/>
                                            <h5> Easy Connect</h5> 
                                            </div> 
                                        </div>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                                <img src={iconTwo}/>
                                                <h5> Advance Alerts</h5>
                                            </div>  
                                        </div>
                                    </div>
                                    <br/> <br/>
                                    <div className='row'>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                            <img src={iconThree}/>
                                            <h5> Live Vital Updates</h5> 
                                            </div> 
                                        </div>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                                <img src={iconFour}/>
                                                <h5> User Friendly</h5>
                                            </div>  
                                        </div>
                                    </div>

                                    </div>
                                </div>

                            </div>
                        </div> 
                        <div className='row laptop-div'> 
                            <div className='col-md-6'> 
                                <div className='system-d'>
                                <div className='row'>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                            <img src={iconFive}/>
                                            <h5> Live Tracking</h5> 
                                            </div> 
                                        </div>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                                <img src={iconSix}/>
                                                <h5> Tele Diagnosis</h5>
                                            </div>  
                                        </div>
                                    </div>
                                    <br/> <br/>
                                    <div className='row'>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                            <img src={iconSeven}/>
                                            <h5> Digital Document</h5> 
                                            </div> 
                                        </div>
                                        <div className='col-md-6 col-6'>
                                            <div className='system-card'> 
                                                <img src={iconEight}/>
                                                <h5> Affordable</h5>
                                            </div>  
                                        </div>
                                    </div>

                                </div> 
                            </div>
                            <div className='col-md-6'>
                            <img src={laptop} className='laptop-system'/>
                            </div>
                        </div>
                        
                    </div> 
                </div>
            </div>

        </div>
        

        
     </div> 
    </>
    
  )
}
export default SystemOne;




