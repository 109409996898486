import React, { useState, useEffect } from 'react';
import VectorImg from "../assets/images/Vector.png";
import RightSideArrow from "../assets/images/arrow-right.png"; 
import Vectors1 from "../assets/images/main-img.svg"; 
import { NavLink } from 'react-router-dom';
import Spacing from '../spacing';

function Banner() {
  const texts = ["Hospitals", "Doctors", "Healthcare "]; // Add more texts as needed
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-6 banner_leftside_view'>
        <Spacing lg="100" md="80" />
          <div className='VectorImg_view'>
            <img src={VectorImg} alt='VectorImg' />
            <p>LIFE-SAVING AI TECHNOLOGY </p>
          </div>
          <h1>Advanced Emergency Management Software for  <span className='main_banner_head'>{texts[currentTextIndex]}</span>Professionals</h1>
          <p> Revolutionizing Emergency Medical Services</p>
          <NavLink exact to="/request">
            <button className='request_demo'>Request Demo<img src={RightSideArrow} alt="RightSideArrow" /></button>
          </NavLink>
        </div>

        <div className='col-lg-6 col-xl-6 col-md-6 banner_rightside_view'>
          <img src={Vectors1} alt="Vectors1" className='img-vector'/>
        </div>

      </div>
    </div> 
    
    
    </> 
  )
}

export default Banner;
