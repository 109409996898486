import React from 'react'
import DM from '../assets/impact/8.png';
import './hero.css'
import Critical from './critical';
import Outcome from './outcome'
import Spacing from '../spacing';
import Health from './health';
import Factors from './factors';
import Patent from './patent'; 
import Hospital from './hospital';
import LoginForm from './loginForm';
import Footer from '../footer';
import BusinesOne from './businesOne';
import Client from '../home/client';
import Navbar from '../home/Navbar';
import impOne from '../assets/images/icon/imp-1.png';
import impTwo from '../assets/images/icon/imp-2.png';
import impThree from '../assets/images/icon/imp-3.png';
import { PatientOne } from './PatientOne';


function Hero() {
    return (
        <>
            <div className=''>

                <div class='container-fluid hero_bg'>
                    <div class='row'>
                        <header>
                            <Navbar/>

                        </header>
                    </div>

                    <div class='row hero_mobile'>
                        <div class=' col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 hero_mobile_sub'>
                            <div class='hero-img'>
                                <img src={DM} alt="image" />
                            </div>
                        </div>
                        <div class=' col-12  col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <div class='container'>
                                <div class='row'>
                                    <div class=' col-md-10  hero-text'>
                                        <h2>Emergencies</h2>
                                        <h2>carry no</h2>
                                        <h1>warnings!</h1>
                                        <p>Delayed hospital admission of critically ill patients is associated with increased mortality.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='bg'> 
                            <div className='row impact'>
                                <div className='col-md-4'> </div>

                                <div className='col-md-2 imp-one'>
                                    <div className='impa-card'>
                                      <img src={impOne}/>
                                      <h3>50%</h3>
                                      <p>of Heart attack cases reach the hospital late</p>
                                    </div> 
                                </div>
                                <div className='col-md-2 imp-two'>
                                    <div className='imp-2'>
                                        <div className='impa-card'>
                                        <img src={impTwo}/>
                                        <h3>58%</h3>
                                        <p>Trauma deaths occur in the first hour</p>
                                        </div> 
                                    </div>
                                </div>
                                <div className='col-md-2 imp-three'>
                                    <div className='impa-card'>
                                      <img src={impThree}/>
                                      <h3>30%</h3>
                                      <p>of Mortality is due to delays in emergency care</p>
                                    </div> 
                                </div>
                                <div className='col-md-2'>
 
                                </div>
                            </div> 
                        </div>              
                    </div>
                </div>
            </div>
            

            <Spacing lg="80" md="100" />
            <Spacing lg="50" md="80" />
            <Spacing lg="50" md="80" />
            </div>
            <Spacing lg="50" md="80" />
            <div className='container'>
                <div className='row'>
                <div className='col-lg-2 col-xl-2'>  </div>
                <div className='col-lg-8 col-md-12 col-xl-8'>
                <div className='the_solution_text'>
                    <p>The Solution</p>
                    <h4>Our solution is unique in its ability to provide a comprehensive approach that integrates accessibility, real-time data transfer, digital documentation, advanced warning systems, and deterioration prediction. This distinctive combination of features makes it an essential tool for emergency medical services</h4>
                    <span className='the_solution_text_span'></span>
                </div>
                </div>
                <div className='col-lg-2 col-xl-2'>  </div>
                </div>
            </div>
            <Spacing lg="50" md="80" />
             <Critical />
            <Spacing lg="50" md="80" />
            <Health />
            <Spacing lg="50" md="80" />
            <Factors />
            <Spacing lg="50" md="80" />
            <Outcome />
           
            <Spacing lg="150" md="80" />
            <PatientOne/> 
            {/* <Spacing lg="50" md="80" />
            <Hospital /> */}
            <Spacing lg="50" md="80" />
            <BusinesOne/>
            <Spacing lg="150" md="80" />
           
            <LoginForm />
            <Spacing lg="100" md="80" /> 
              <Footer />
            {/* <Client />
            <Spacing lg="50" md="80" />
             <LoginForm /> */}
            {/* <Spacing lg="100" md="80" /> */}
            {/* <Footer /> */}
        </>
    )
}

export default Hero
