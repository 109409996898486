import React from 'react'
import './ourStory.css';
import Spacing from '../spacing';
import man from '../assets/about/1_man.png'
import mann from '../assets/about/3_man.png'
import she from '../assets/about/2_girl.png'
import facebook from '../assets/icons/facebook.png'
import insta from '../assets/icons/insta.png'
import twitter from '../assets/icons/twitter.png'

function OurStoryReview() {
  return (
   
      <>
      <div className='container'>
        <div className='row'>
        <div className='col-lg-1 col-xl-1'>
                </div>
            <div className='col-md-6 col-lg-5 col-xl-5 col-sm-12 col-12'>
                <div className='our_story_review_head'>
                    <h1>Meet the Kode Blue team </h1><h1> Trailblazers in Emergency Tech </h1>
                </div>
            </div>
            <div className='col-md-5 col-lg-5 col-xl-5 col-sm-12 col-12'>
                <div className='our_story_review_para'>
                    <p>In the wake of the pandemic, a new perspective dawned upon us, exposing the gaps in Emergency Medical Services.
                         We realized that our focus needed a shift, and thus, Kode Blue Technologies was born</p>
                         {/* <p>Fuelled by an unwavering passion for innovation, we're rewriting the emergency care norms. Patient-Centric is our mantra where every line of code is dedicated to their well-being. Our foundation is our Integrity, our story is one of Excellence,
                             from idea to service, delivering exceptional value. Together, we're redefining emergency care's future</p> */}
                </div>
            </div>
            {/* <div className='col-lg-1 col-xl-1'>
                </div> */}
        </div>
        <Spacing lg="50" md="80" />
        <div className='row'>
       
            <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                <div className='our_story_card'>
                    <div className='man_img_div'>
                        <img src={man} alt="" />
                    </div>
                    <h2>Varun Chintha</h2>
                    <h4>Founder, CEO</h4>
                    <p>Product Design & Development Head, Business Analyst</p>
                    <div className='icons_images'>
                        <img src={facebook} alt="social media insta" />
                        <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" />
                    </div>
                   
                </div>
            </div>
          
            <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
            <div className='our_story_card'>
                    <div className='man_img_div'>
                        <img src={she} alt="" />
                    </div>
                    <h2>Kranthi Juluri</h2>
                    <h4>Co-Founder, CMO</h4>
                    <p>Sales & Marketing Head</p>
                    <div className='icons_images'>
                        <img src={facebook} alt="social media insta" />
                        <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" />
                    </div>
                   
                </div>
            </div>
            <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
            <div className='our_story_card'>
                    <div className='man_img_div'>
                        <img src={mann} alt="" />
                    </div>
                    <h2>Praveen Renukuntla</h2>
                    <h4>COO</h4>
                    <p>Operations Head</p>
                    <div className='icons_images'>
                        <img src={facebook} alt="social media insta" />
                        <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" />
                    </div>
                   
                </div>
            </div>
            <div className='col-md-6 col-lg-3 col-xl-3 col-sm-12 col-12'>
                <div className='our_story_card'>
                    <div className='man_img_div'>
                        <img src={man} alt="" />
                    </div>
                    <h2>Dr. Chetan Chintha</h2>
                    <h4>Advisor</h4>
                    <p>Digital Life Sciences</p>
                    <div className='icons_images'>
                        <img src={facebook} alt="social media insta" />
                        <img src={insta} alt="social media insta" />
                        <img src={twitter} alt="social media insta" />
                    </div>
                   
                </div>
            </div>
        </div>
      </div>
      
      
      
      </>
    
  )
}

export default OurStoryReview
