import React from 'react'
import './patentCard.css';
import busines from '../assets/impact/9.png'
import Spacing from '../spacing';

function BusinesOne() {
    return (
        <>
            <div className='container  '>
           
                <div className='row background-image-div hero_mobile'>
                <div className='container plus_div '>
                <div className='row '>
                    <div class="col-md-12 col-lg-6 col-12 col-sm-12 hero_mobile_sub">
                        <div className='busines_image'>
                            <img src={busines} alt="" />
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6 col-12 col-sm-12 '>
                    <Spacing lg="20" md="80" />
                        <div className='busines_text'>
                            {/* <h1>Where Every </h1>
                            <h1> Second </h1>
                            <h1>  Matters</h1> */}
                                    <h1>We are in the</h1> 
                             <h1>business of</h1> 
                             <h1> saving lives and</h1>
                            <h1> livelihoods.</h1> 

                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinesOne
