import React from 'react';
import Spacing from '../spacing'
import Navbar from '../home/Navbar'
import Banner from '../home/Banner'
import Request from './index'
import Client from '../home/client'
import Busines from '../impact/busines'
import Footer from '../footer';
import BannerRequest from './bannerRequest';
import OurImpact from './ourImpact';

function ReqestDemo() {
  return (
    <>
   <BannerRequest />
    <Spacing lg="100" md="80" />
    <Client/>
    <Spacing lg="100" md="80" />
    <Busines/>
    <Spacing lg="50" md="80" />
    <OurImpact />
    <Spacing lg="80" md="80" />
    <Footer/>
    
    </>
  )
}

export default ReqestDemo
