import React from 'react'
import health from '../assets/impact/3.png'

function Health() {
  return (
    <>
       
       <div className='container health-bg'>
        <div className='row hero_mobile'>
           
            <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12 hero_mobile_sub factor_colum'>
                <img src={health} alt="Healthcare Image" />
            </div>
            <div className='col-md-12 col-lg-6 col-xl-6 col-sm-12 col-12 factor_colum '>
                <div className=''>
                    <div className='health-text'>
                        <h1>Transforming Emergency Healthcare Delivery.</h1>
                        {/* <h1>and ecosystem health</h1> */}
                        <p>Seamless real-time data sharing between ambulances and hospitals ensures timely and targeted medical interventions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default Health;
