import React from 'react'
import Spacing from '../spacing';
import love from '../assets/about/love_icon.png';
import './core.css'

function OurValues() {
  return (
    
      
    <>
    <div className='container-fluid core_values_bg'>
        <div className='container '>
        <Spacing lg="100" md="80" />
            <div className='our_core_values_text'>
                <h1>Our Core Values</h1>
            </div>
            <Spacing lg="50" md="80" />
            <div className='row'>
            <div className='col-lg-1 '>
                </div>
                <div className='col-lg-4 col-xl-3 col-md-12 col-12 col-sm-12 our_values_card'>
                    <div className='our_values_card_text'>
                        <img src={love} alt="" />
                        <h4>Innovation 1</h4>
                        <p>Constantly innovating solutions for evolving emergency care needs</p>
                    </div>
                </div>
                <div className='col-lg-3 col-xl-3 col-md-12 col-12 col-sm-12 our_values_card'>
                    <div className='our_values_card_text'>
                        <img src={love} alt="" />
                        <h4>Patient-Centric </h4>
                        <h4> Approach 2</h4>
                        <p>Prioritizing patients, creating tech that genuinely improves well-being</p>
                    </div>
                </div>
                <div className='col-lg-3  col-xl-3 col-md-12 col-12 col-sm-12 our_values_card'>
                    <div className='our_values_card_text'>
                        <img src={love} alt="" />
                        <h4>Integrity 3</h4>
                        <p>Operating ethically, transparently, and honestly, for fostering trust among all</p>
                    </div>
                </div>
                <div className='col-lg-1 '>
                </div>
            </div>
            <div className='row'>
            <div className='col-lg-3 '>
                </div>
               
                <div className='col-lg-3 col-xl-3 col-md-12 col-12 col-sm-12 our_values_card'>
                    <div className='our_values_card_text'>
                        <img src={love} alt="" />
                        <h4>Collaboration 4</h4>
                        <p>Partnering for positive change in healthcare with stakeholders</p>
                    </div>
                </div>
                <div className='col-lg-3 col-xl-3 col-md-12 col-12 col-sm-12 our_values_card'>
                    <div className='our_values_card_text'>
                        <img src={love} alt="" />
                        <h4>Commitment to  </h4>
                        <h4>Excellence 5</h4>
                        <p>Delivering exceptional value, from development to service</p>
                    </div>
                </div>
                <div className='col-lg-3 '>
                </div>
            </div>
            <Spacing lg="80" md="80" />
        </div>
    </div>
    
    </>
  )
}

export default OurValues
