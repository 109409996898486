// import React from "react";

// import { useState, useEffect } from "react";
// import "./contact.css";

// const ContactSection = () => {


  

//     const [formValues, setformValues] = useState({
//         username: "",

//         email: "",

//         subject: "",

       

       

//         message: "",
//     });

//     const [submitSucess, setSubmitSuccess] = useState(false);

   

//     const [rcheck, setRcheck] = useState(true);
//     const [rcheck2, setRcheck2] = useState(false);

//     let oneClick = () => {
//         setRcheck(true);
//         setRcheck2(false);
//     };

//     let twoClick = () => {
//         setRcheck2(true);
//         setRcheck(false);
//     };

    

//     const eventChange = (e) => {
//         const { name, value } = e.target;
//         setformValues({ ...formValues, [name]: value });

//         setformErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: validate({ ...formValues, [name]: value })[name],
//         }));
//     };

//     const [formErrors, setformErrors] = useState({
//         error: "please enter data",
//     });

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         setformErrors(validate(formValues));
//     };

//     const validate = (values) => {
       
//         const re = /^[a-zA-ZÀ-ÖØ-öø-ÿ '.]+([-'][a-zA-ZÀ-ÖØ-öø-ÿ '.]+)*$/

       
//         const email_re =
//             /^[a-zA-Z]([a-zA-Z0-9._%+-]+)@([^\-\_][a-zA-Z0-9.-]+[^\-\_]\.[a-zA-Z]{2,})$/;

//         const errors = {};

//         if (!values.username) {
//             errors.username = "please enter your name";
//         } else if (formValues.username.length < 2) {
//             errors.username = "please enter at least 3 characters";
//         }
//         else if (!re.test(values.username)) {
//             errors.username = "only alphabets are allowed";
//         }

//         if (!values.email) {
//             errors.email = "please enter email";
//         } else if (!email_re.test(values.email)) {
//             errors.email = "please enter valid email";
//         }

//         if (!values.subject) {
//             errors.subject = "please enter subject";
//         } else if (formValues.subject.length < 5) {
//             errors.subject = "please enter at least 6 characters";
//         }



//         if (rcheck === false && rcheck2 === false) {
//             errors.radio = "please chose one option";
//         }



//         return errors;
//     };

//     useEffect(() => {
//         let objectLength = Object.keys(formErrors).length;

//         if (objectLength === 0) {
//             console.log("successfully submited ");

//             const requestOptions = {
//                 method: "POST",
//                 headers: { "Content-Type": "application/json" },
//                 body: JSON.stringify(formValues),
//             };
//             fetch("https://reqres.in/api/posts", requestOptions)
//                 .then((response) => response.json())
//                 .then((res) => {
//                     if (res) {
//                         setSubmitSuccess(true);
//                     } else {
//                         setSubmitSuccess(false);
//                     }
//                     console.log("conttttttttttttttttttttttt", res);


//                     setTimeout(() => {
//                         setSubmitSuccess(false);
//                     }, 3000);

//                 })
//                 .catch((err) => console.log(err));

//             formValues.username = "";
//             formValues.email = "";
//             formValues.subject = "";
//             formValues.message = "";
//             setRcheck(false);
//             setRcheck2(false);
//         }


//     }, [formErrors]);
//     const handleInputFocus = (event) => {
//         event.target.classList.add("focus")

//     }
//     const handleInputBlur = (event) => {
//         event.target.classList.remove("focus")

//     }

//     return (
//         <>


//             <div className="container contact-section ">
//                 <div className="address">
//                     <p className="contact-us-head">Contact us</p>
//                     <div className="d-flex gap-2" style={{ marginLeft: "-22px" }}>

//                         <div>

//                         </div>
//                     </div>
//                     <p className="address-para pt-5">sportsverse@metalok.io</p>
//                     <p className="address-para">+916266868051</p>


//                 </div>

              

//                 <div className="contact-form ">
//                     <div className="send-us-heading">
//                         <h4>Send us a Message</h4>
//                     </div>
//                     <form className="form-section">
//                         <div className="input-section ">
//                         <div className=" input-group">
//                             <label htmlFor="name">Name : </label>
//                             <input
//                                 type="text"
//                                 id="name"
//                                 placeholder=""
//                                 name="username"
//                                 value={formValues.username}
//                                 required
//                                 onChange={eventChange}
//                                 onFocus={handleInputFocus}
//                                 onBlur={handleInputBlur}
//                                 autocomplete="off"
//                             />
//                             </div>
//                             {<h6 className="error-name">{formErrors.username}</h6>}
//                             <label htmlFor="email">Email : </label>
//                             <input
//                                 id="email"
//                                 type="text"
//                                 name="email"
//                                 value={formValues.email}
//                                 placeholder=""
//                                 onChange={eventChange}
//                                 onFocus={handleInputFocus}
//                                 onBlur={handleInputBlur}
//                                 autocomplete="off"
//                             />
//                             {<h6 className="error-email">{formErrors.email}</h6>}
//                             <label htmlFor="subject">Subject : </label>

//                             <input
//                                 id="subject"
//                                 type="text"
//                                 name="subject"
//                                 value={formValues.subject}
//                                 placeholder=""
//                                 onChange={eventChange}
//                                 onFocus={handleInputFocus}
//                                 onBlur={handleInputBlur}
//                                 autocomplete="off"
//                             />
//                             {<h6 className="error-phone">{formErrors.subject}</h6>}
//                         </div>


//                         <div className="message-input">
//                             <label htmlFor="message">Message :</label>
//                             <input
//                                 type="text"
//                                 id="message"
//                                 placeholder=""
//                                 name="message"
//                                 value={formValues.message}
//                                 required
//                                 onChange={eventChange}
//                                 onFocus={handleInputFocus}
//                                 onBlur={handleInputBlur}
//                                 autocomplete="off"
//                             />
//                             {<h6 className="error-message">{formErrors.message}</h6>}
//                         </div>

//                         <div className="m-auto">

//                             <button className="submit-btn" onClick={handleSubmit}>
//                                 {submitSucess ? <div className="submited-btn">{"Submited"}</div> : "Submit"}
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// };
// export default ContactSection;

// export default ContactSection;
// import React from 'react';
// import "./contact.css";

// function ContactSection() {
//   return (
//    <>
//    <div className='wrapper'>
//     <div className='form-wraper sign-in'>
//         <form actions="">
//             <h2>login</h2>
//             <div className='input-group'>
//                 <input type="text" required ></input>
//                 <label>username</label>

//             </div>

//         </form>
//     </div>
//    </div>
   
   
//    </>
//   )
// }

// export default ContactSection

import React from 'react'
import './contact.css'
import Spacing from '../spacing'
import phone from '../assets/icons/phone.png'
import mail from '../assets/icons/mail.png'
import twit from '../assets/about/twitter_black.png'
import insta from '../assets/about/insta_black.png'
import dis from '../assets/about/discard_black.png'
import loc from '../assets/icons/location.png'

function ContactSection() {
  return (
    <>
    <div className='container'>
        <div className='contact_us_text_head'>
            <h1>Contact Us</h1>
            <p>Any question or remarks? Just write us a message!</p>
        </div>
        <Spacing lg="50" md="80" />
        <div className='row contact_main_bg'>
            <div className='col-lg-5 col-xl-5 col-md-12 col-12 col-sm-12 contact_left_bg'>

                <div className='contact_information_div'>
                    {/* <h1>Contact Information</h1> */}
                    <h1>KODE BLUE TECHNOLOGIES</h1>
                    <Spacing lg="80" md="80" />
                    <div className='phone_display_div'>
                        <img src={phone} alt="" />
                        <p>8919214950</p>
                    </div>
                    <Spacing lg="30" md="80" />
                    <div className='phone_display_div'>
                        <img src={mail} alt="" />
                        <p>info@kodeblue.in</p>
                    </div>
                    <Spacing lg="30" md="80" />
                    <div className='phone_display_div'>
                        <img src={loc} alt="" />
                        <p>5-11-1365, Hanuman Nagar, Hanuma Konda, Warangal Urban, Telangana, India. 506009</p>
                    </div>
                    <Spacing lg="100" md="80" />
                    <div className='phone_display_div'>
                        <img src={twit} alt="" />
                        <img src={insta} alt="" />
                        <img src={dis} alt="" />
                    </div>

                </div>
            </div>
            <div className='col-lg-7 col-xl-7 col-md-12 col-12 col-sm-12'>
                
                <div className='form-wrapper'>
                    <form actions="">
                     <div className='input-group_div'>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>FIRST NAME</label>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Last Name</label>
                        </div>
                        </div>
                        <div className='input-group_div'>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Email</label>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Phone Number</label>
                        </div>
                        </div>
                        <div className='input-group message_div'>
                            {/* <input type="text"  placeholder='Write your message..' required/> */}
                            <input type="text" required/>
                            <label className='message_label'>Message</label>
                        </div>
                        <Spacing lg="50" md="80" />
                        <div className='send-button-div'>
                        <button className='send-button'>Send Message</button>
                     </div>
                    </form>
                    <Spacing lg="150" md="80" />
                </div>
            </div>
           
        </div>
    </div>
    
    </>
  )
}

export default ContactSection;