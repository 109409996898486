import React from 'react'
import './style.css';
import Spacing from '../spacing'
import Busines from '../impact/busines'
import Footer from '../footer'
import LoginForm from '../impact/loginForm'
import Client from './client'
import System from './system'
import  SystemOne  from './systemOne'
import { HowWork } from './howWork'
import Navbar from './Navbar'
import Banner from './Banner'
import Watch from './Watch';
import OurImpact from '../requestDemo/ourImpact';
import Bestemergencytransitcareassured from './Bestemergencytransitcareassured'

function HomePage() {
    return (
        <>
            
            <div className='main_section_view'>
                <div className='main_section_view_layout'>
                    <Navbar/>
                    <div className='container'>
            <div className='row'>
                    <Banner/>
                    </div>
            </div>
                </div>
            </div>
            <Spacing lg="60" md="10" />
            
            <div className='watch_our_vedio_here_view'>
                <Watch/>
            </div>
            {/* <div className='Beste_mergency_transitcare_assured_view'> */}
                <Bestemergencytransitcareassured/>
            {/* </div> */}
            <Spacing lg="150" md="10" />
            
            <HowWork/> 
            <Spacing lg="200" md="60" />
            
            <br/>
            <br/>
            <Spacing lg="10" md="10" />
            <SystemOne/>
            <Spacing lg="50" md="80" />
            {/* <System /> */}
            <Spacing lg="50" md="80" />
            <Client />
            <Spacing lg="50" md="80" />
            <Busines />
            <Spacing lg="50" md="80" />
            <OurImpact />
            <Spacing lg="150" md="80" />

            <LoginForm />
            <Spacing lg="100" md="80" />
            <Footer /> 

        </>
    )
}

export default HomePage
