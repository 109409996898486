import React from 'react'
import Spacing from '../spacing';
import OurValues from './ourValues';
import OurStoryReview from './ourStoryReview';
import ContactSection from './contactSection';
import Footer from '../footer'
import AboutBanner from './aboutBanner';
import Navbar from '../home/Navbar';

function About() {
  return ( 
    <>
    <Navbar/>
    <AboutBanner />
     <Spacing lg="150" md="80" />
    <OurValues />
    <Spacing lg="150" md="80" />
    <OurStoryReview />
    <Spacing lg="150" md="80" />
    <ContactSection />
    <Spacing lg="150" md="80" />
    <Footer />
    </>
  )
}

export default About;
