import React from 'react'
import './client.css';
import manone from '../assets/home/1-man.png'
import stars from '../assets/home/stars.png'

function Client() {
  return (
    <>
      {/* <div className='container'>
    <div className='row'>
      <div className=''></div>
    </div>
   </div> */}
      <div>
        <section>
          <div className="container">
              <div className='cards_text_head'>
                <h1>Here’s what our amazing clients</h1>
                <h1> are saying</h1>

              </div>
            <div className="container man_div_bg">
              <div className="row my-2">
                <div className="col-lg-2  mb-3  ">
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">
                  <div className='man_div'>
                    <img src={manone} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Lukas Graham</h4>
                      <p>Apollo Hospitals</p>
                      <img src={stars} alt="" />
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">


                <div className='man_div'>
                    <img src={manone} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Lukas Graham</h4>
                      <p>Apollo Hospitals</p>
                      <img src={stars} alt="" />
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                  </div>
                </div>
                <div className="col-lg-2 mb-3">
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-2 mb-3 ">
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12 mb-3 career-component3">
                  <div className='man_div'>
                    <img src={manone} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Lukas Graham</h4>
                      <p>Apollo Hospitals</p>
                      <img src={stars} alt="" />
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12  mb-3 career-component3">


                <div className='man_div'>
                    <img src={manone} alt="" className='man_div_img' />
                    <div className='stars_div'>
                      <h4>Lukas Graham</h4>
                      <p>Apollo Hospitals</p>
                      <img src={stars} alt="" />
                    </div>
                  </div>
                  <div className='text_stars'>
                    <p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                  </div>
                </div>
                <div className="col-lg-2 mb-3">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}

export default Client
