import React from 'react'
import DM from '../assets/impact/8.png';
import '../impact/hero.css'
import '../about/contact.css';
import Spacing from '../spacing';
import requestbanner from '../assets/images/request.png';


import Navbar from '../home/Navbar';

function BannerRequest() {
    return (
        <>
            <div className=''>

                <div class='container-fluid hero_bg'>
                    <div class='row'>
                        <header>
                            <Navbar/>

                        </header>
                        <Spacing lg="50" md="80" />
                    </div>
                    <div class='container'>
                    <div class='row hero_mobile'>
                        <div class=' col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 hero_mobile_sub'>
                            <div class='hero-img'>
                            <div className='request_banner_heading_main'>
                <h1>Take a Product Tour & A Custom Demo</h1>
                <img src={requestbanner} alt="" />
              </div>
                            </div>
                        </div>
                        <div class=' col-12  col-sm-12 col-md-12 col-lg-7 col-xl-7 '>
                        <div className='form-wrapper  request_form_bg' >
                    <form actions="" className=''>
                    <Spacing lg="50" md="80" />
                    <h1 className='request_heading-form'>Request a Demo</h1>
                     <div className='input-group_div'>
                    
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>FIRST NAME</label>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Second Name</label>
                        </div>
                        </div>
                        <div className='input-group_div'>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label> Business Email</label>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Phone Number</label>
                        </div>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Hospital Name</label>
                        </div>
                        <div className='input-group'>
                            <input type="text"  required/>
                            <label>Designation</label>
                        </div>
                        {/* <div className='input-group message_div'>
                            <input type="text"  placeholder='Write your message..' required/>
                            <label className='message_label'>Message</label>
                        </div> */}
                        <Spacing lg="50" md="80" />
                        <div className='request-button-div'>
                        <button className='request-button'>Request Demo</button>
                     </div>
                     <Spacing lg="50" md="80" />
                    </form>
                    <Spacing lg="150" md="80" />
                </div>

                        </div>
                    </div>
                    </div>
                </div>
              
                <Spacing lg="50" md="80" />
            </div>
           
           
          
          
        </>
    )
}

export default BannerRequest;
